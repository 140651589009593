<template>
    <div class="learnQuestion">
        <div class="back-box">
            <div class="back pointer" @click="back">
                <img src="@/assets/img/questionNew/37.png" />
                <span>返回</span>
            </div>
        </div>
        <!-- 题目 -->
        <div class="question-body">
            <div class="question-left">
                <!-- 头部 -->
                <div class="question-header">
                    <div class="question-header-left">
                        <img src="@/assets/img/questionNew/35.png" class="icon mr6" />

                        <div class="questionType mr4">{{ queType[Number(questionList[current - 1]?.questionComposeType ?
                            questionList[current - 1]?.questionComposeType : questionList[current - 1]?.questionType)]
                            }}</div>
                        <div class="number">
                            （<span>{{ current }}</span>/{{ questionTotal }}题）
                        </div>
                    </div>
                    <!-- 收藏 -->
                    <div class="question-header-right pointer" @click="updateSc">
                        <img src="@/assets/img/questionNew/38.png" v-show="!questionList[current - 1]?.collect" />
                        <img src="@/assets/img/questionNew/36.png" v-show="questionList[current - 1]?.collect" />
                        <span>收藏</span>
                    </div>
                </div>

                <!-- 主体 -->
                <div class="question-main" id="scroll">
                    <template v-if="questionList.length != 0">
                        <!-- {{ questionList[current - 1] }} -->
                        <questionItemzh v-if="questionList[current - 1].composeId" ref="questionRef" :pageType="3"
                            :current="current" :info="questionList[current - 1]" :examStatus="4" :isStudyCenter="1"
                            :isShowUserAnswer="false" @answerFn="answerFn" />
                        <questionItem v-else ref="questionRef" :pageType="3" :current="current"
                            :info="questionList[current - 1]" :examStatus="4" :isStudyCenter="1"
                            :isShowUserAnswer="false" @answerFn="answerFn" />
                    </template>
                    <el-empty description="暂无数据~" class="mt100" v-else></el-empty>
                </div>

                <!-- 上一题 下一题 -->
                <div class="question-bottom-but">
                    <div :class="{ pointer: true, 'question-bottom-but-item': true, 'question-bottom-but-disable': current <= 1 }"
                        @click="preQuestion">
                        <div class="arrow">
                            <i class="el-icon-arrow-left"></i>
                            <i style="color: #FF655A;" class="el-icon-arrow-left"></i>

                        </div>
                        <span>上一题</span>
                    </div>
                    <div :class="{ pointer: true, 'question-bottom-but-item': true, 'question-bottom-but-disable': current >= questionTotal }"
                        @click="nextQuestion" v-if="current < questionTotal">
                        <span>下一题</span>
                        <div class="arrow">
                            <i class="el-icon-arrow-right"></i>
                            <i style="color: #FF655A;" class="el-icon-arrow-right"></i>
                        </div>
                    </div>
                    <div class="question-bottom-but-item pointer" @click="addVipQuestionResultAnswer" v-else>
                        <span>提交</span>
                    </div>
                </div>
            </div>
        </div>

        <!-- 返回 -->
        <div class="mask-fixed" v-if="backTipsShow">
            <div class="sub-tips-box">
                <div class="sub-tips">是否结束{{ isShowAnswer ? "背题" : "刷题" }}并返回？</div>
                <div class="sub-tips-but">
                    <div class="sub-tips-but-item sub-tips-but-left pointer" @click="cancelBackTips">取消</div>
                    <el-button class="sub-tips-but-item sub-tips-but-right pointer" @click="confirmBackTips"
                        type="primary">确认</el-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from 'vue'
import { getInfo } from '@/api/cookies'
import { queType } from "@/api/emun";
import questionItem from '@/components/questionNew/questionItem.vue'
import questionItemzh from '@/components/questionNew/questionItemZh.vue'

import { AnswerClient } from '@/api/task/answerClient'
const answerClient = new AnswerClient()
export default {
    components: { questionItem, questionItemzh },
    data() {
        return {
            queType: queType,
            screenNumber: 0,
            info: {},
            questionType: [-1],
            questionState: 0,
            questionCategory: [0],
            questionMode: 1,
            questionNumber: 10,
            questionList: [],
            questionTotal: 0,
            current: 0,
            backTipsShow: false,
            isShowAnswer: false,
            timeIn: '',
            resultId: ''
        }
    },
    async created() {
        this.userInfo = getInfo()
        if (!this.userInfo) {
            Vue.prototype.goLoginView(false)
            return
        }
        this.timeIn = new Date();
        this.resultId = this.$route.query.resultId
        this.isbeiTi = this.$route.query.isbeiTi ? this.$route.query.isbeiTi : 0

        await this.selectVipResultAnalysisPC()
    },
    methods: {
        async selectVipResultAnalysisPC() {
            answerClient.selectVipResultAnalysisPC({
                resultId: this.resultId,
            }).then(res => {
                if (res.data.rows && res.data.rows.length > 0) {
                    this.current = 1;

                    let qcomposeId = ''
                    let qIndex = 1
                    res.data.rows.forEach(e => {
                        if (e.questionOption) {
                            e.questionOption = JSON.parse(e.questionOption)
                            e.isShowAnswer = false
                            e.questionUserAnswerImg = e.questionUserAnswerImg ? e.questionUserAnswerImg.split(',') : []
                        }
                        if (e.composeId) {
                            if (qcomposeId) {
                                if (e.composeId == qcomposeId) {
                                    e.questionindex = qIndex
                                    qIndex++
                                } else {
                                    qcomposeId = e.composeId
                                    qIndex = 1
                                    e.questionindex = qIndex
                                    qIndex++

                                }

                            } else {
                                qcomposeId = e.composeId
                                e.questionindex = qIndex
                                qIndex++
                            }

                        }
                        // if (this.isbeiTi == 1) {
                        e.isShowAnswer = true
                        e.isAnswer = true
                        // }

                    });


                    this.questionList = res.data.rows
                    this.questionTotal = res.data.dataNum
                } else {
                    this.questionList = []
                    this.questionTotal = 0
                }
                this.$forceUpdate()
            })
        },
        back() {
            // this.backTipsShow = true;
            this.$router.go(-1);
        },
        cancelBackTips() {
            this.backTipsShow = false;
        },
        confirmBackTips() {
            this.addVipQuestionResultAnswer()
            // this.$router.go(-1);
        },
        // 上一题
        preQuestion() {
            if (this.current > 1) this.current--;
        },
        // 下一题
        async nextQuestion() {
            if (this.current < this.questionList.length) this.current++;
            if (this.current == this.questionList.length && this.current != this.questionTotal) {
            }
        },
        updateSc() {
            if (this.questionList.length == 0) return;
            answerClient.collectSubject({
                subjectId: this.questionList[this.current - 1].subjectId,
                isCollect: this.questionList[this.current - 1].collect ? false : true,
            }).then((res) => {
                if (res.code == 0) {
                    this.$set(this.questionList[this.current - 1], 'collect', !this.questionList[this.current - 1].collect);
                    this.$forceUpdate();
                }
            });
        },
        // 回答问题
        answerFn(item) {
            this.questionList[this.current - 1].isShowAnswer = true;
            this.questionList[this.current - 1].isAnswer = true;
            const info = JSON.parse(JSON.stringify(item));
        },
        async addVipQuestionResultAnswer() {
            this.$router.go(-1);

            return
            const currentDate = new Date();
            const timeDiff = Math.abs(currentDate - this.timeIn);
            const secondsDiff = Math.floor(timeDiff / 1000);
            let data = JSON.parse(JSON.stringify(this.questionList))
            console.log(data, '-----data--------');

            data.forEach(e => {
                e.questionUserAnswerImgZ = []
                if (e.questionUserAnswerImg && e.questionUserAnswerImg.length > 0) {
                    e.questionUserAnswerImg.forEach(el => {
                        e.questionUserAnswerImgZ.push(el.url)
                    });
                }
            });
            data.forEach(e => {
                if (e.questionUserAnswerImgZ) {
                    e.questionUserAnswerImg = e.questionUserAnswerImgZ.join(',')
                }
                if (e.questionOption) {
                    e.questionOption = e.questionOption.join(',')
                }
            });
            await answerClient.addVipQuestionResultAnswer({
                courseId: this.courseNameId,
                type: this.type,
                typeName: this.questionModeName,
                problemTime: secondsDiff,
                questionResultAnswerDtoList: data
            }).then(res => {
                if (res.code == 0) {
                    this.$router.go(-1);

                }
            })
        }
    }
}
</script>
<style lang="less" scoped>
.learnQuestion {
    background-color: #f7f8fb;
    padding-bottom: 60px;
}

.back-box {
    width: 1200px;
    height: 60px;
    margin: 0 auto;
    display: flex;
    align-items: center;

    .back {
        display: flex;
        align-items: center;
        font-size: 16px;
        color: #777;
    }

    img {
        width: 9px;
        height: 14px;
        margin-right: 8px;
    }

    span {
        line-height: 1;
    }
}

.question-body {
    width: 1200px;
    height: 757px;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
}

.question-left {
    flex: 1;width: 100%;
    height: 100%;
    background-color: #fff;
    box-shadow: 0 0 6px 1px rgba(204, 204, 204, 0.3);
    border-radius: 10px;
}

.question-header {
    width: 100%;
    height: 64px;
    background-color: #f9fbff;
    border-radius: 10px 10px 0 0;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .question-header-left,
    .question-header-right {
        display: flex;
        align-items: center;
    }

    .icon {
        width: 17px;
        height: 18px;
    }

    .questionType {
        font-size: 16px;
        color: #FF5D51;
    }

    .number {
        color: #aaa;
        font-size: 12px;

        span {
            color: #FF5D51;
        }
    }

    .question-header-right {
        font-size: 14px;
        color: #666;

        img {
            width: 20px;
            height: 20px;
            margin-right: 6px;
        }
    }
}

.question-main {
    height: calc(100% - 64px - 66px);
    overflow-y: auto;
}

.question-bottom-but {
    width: 100%;
    height: 66px;
    box-shadow: 0 -3px 6px 1px rgba(0, 0, 0, 0.05);
    border-radius: 0 0 10px 10px;
    display: flex;
    justify-content: space-between;
}

.question-bottom-but-item {
    width: 250px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #333;

    .arrow {
        width: 8px;
        // height: 13px;
        transform: translateY(1px);

        i {
            // width: 100%;
            // height: 100%;
            display: block;

            &:last-child {
                display: none;
            }
        }
    }

    &:first-child {
        .arrow {
            margin-right: 18px;
        }
    }

    &:last-child {
        .arrow {
            margin-left: 18px;
        }
    }

    &:hover {
        background-color: #f9fbff;
        color: #FF5D51;

        .arrow {
            img {
                &:first-child {
                    display: none;
                }

                &:last-child {
                    display: block;
                }
            }
        }
    }
}

.question-bottom-but-disable {
    color: #aaa !important;
    background-color: transparent !important;
    cursor: default;

    .arrow {
        img {
            display: none !important;
        }
    }
}

.sub-tips-box {
    width: 354px;
    height: fit-content;
    background-color: #fff;
    border-radius: 12px;
    padding: 24px 0;

    .sub-tips {
        font-size: 20px;
        color: #333;
        padding: 0 58px;
        text-align: center;
        line-height: 34px;
        margin-bottom: 32px;
    }

    .sub-tips-but {
        display: flex;
        align-items: center;
        justify-content: center;

        .sub-tips-but-item {
            width: 110px;
            height: 40px;
            margin: 0 20px;
            font-size: 16px;
            border-radius: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: normal !important;
        }

        .sub-tips-but-left {
            background-color: #FFEEED;
            color: #FF5D51;
        }

        .sub-tips-but-right {
            background-color: #FF5D51;
            color: #fff;
            border: none;
        }
    }
}
</style>
